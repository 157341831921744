import React, { useState } from 'react';
import { Avatar, Box, Grid, Typography, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './UserView.styles';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from 'ui/components/Button/Button';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';
import { getLabelByValue } from 'utils/languages';
import { roleList } from 'utils/constUtils';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import { useCoachAvailabilities } from 'hooks/queries';
import CoachAvailabilities from 'ui/components/CoachAvailabilities/CoachAvailabilities';
import './styles.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActiveSettingsCoachModal from 'ui/pages/coach/ActiveSettingsCoachModal';
import useEditCoach from 'hooks/usecase/useEditCoach';
import UserChip from '../UserChip/UserChip';
import CoachActivationSwitch from '../coaches/CoachActivationSwitch';

const linkedInIcon = `${process.env.PUBLIC_URL}/uploads/icons/linkedin-no-color.svg`;
const calendlyIcon = `${process.env.PUBLIC_URL}/uploads/icons/calendly.svg`;

const LinkedInIcon = ({ sx }) => {
  return <Box component="img" src={linkedInIcon} sx={sx} />;
};

const CalendlyIcon = ({ sx }) => {
  return <Box component="img" src={calendlyIcon} sx={sx} />;
};

const UserGeneralInformation = ({
  user,
  sessionLabel,
  onClickNextUser,
  onClickPreviousUser,
  availabilitiesCount,
  directionalArrowsActivated = false,
}) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();

  const contactDetails = [
    { id: 'email', Icon: ({ sx }) => <MailOutlineIcon sx={sx} />, label: user.email, copyMsg: t('email_copied') },
    {
      id: 'phoneNumber',
      Icon: ({ sx }) => <LocalPhoneOutlinedIcon sx={sx} />,
      label: user.phoneNumber,
      copyMsg: t('phone_copied'),
    },
    ...(user.linkedinUrl
      ? [
          {
            id: 'linkedin',
            Icon: ({ sx }) => <LinkedInIcon sx={sx} />,
            label: `${user.firstname} ${user.lastname}`,
            link: user.linkedinUrl,
          },
        ]
      : []),
    { id: 'calendly', Icon: ({ sx }) => <CalendlyIcon sx={sx} />, label: user.calendarLink, link: user.calendarLink },
  ].filter((line) => line.label);

  const onClickContact = (id) => {
    const config = contactDetails.find((contact) => contact.id === id);

    if (config.link) {
      window.open(config.link, '_blank');
    } else {
      clipboardCopy(config.value || config.label);

      if (config.copyMsg) {
        setStateAlert({ open: true, message: config.copyMsg });
      }
    }
  };

  const meetDetails = user.city
    ? [{ id: 'city', Icon: ({ sx }) => <LocationOnOutlinedIcon sx={sx} />, label: user.city }]
    : [];
  meetDetails.push({ id: 'online', Icon: ({ sx }) => <VideocamOutlinedIcon sx={sx} />, label: t('online') });

  const backgroundAvatar = user.picture && user.picture !== 'default' ? 'white' : '#8c9097';

  const disableSectionChip = !user.hoursOfExperience && user.internalCoach == null && user.maxCapacity == null;

  const isInternalCoach = user?.internalCoach === 'INTERNAL' || user?.internalCoach === true;

  return (
    <Box sx={{ ...styles.card, ...styles.generalInformationCard }}>
      <Avatar src={user.picture} sx={{ ...styles.avatar, background: backgroundAvatar }} />
      {directionalArrowsActivated && (
        <Box sx={styles.directionalArrowsContainer}>
          <Button
            sx={{ ...styles.directionalLeft, ...styles.directionArrow }}
            variant="secondary"
            onClick={onClickPreviousUser}>
            <KeyboardArrowLeftIcon sx={{ fontSize: '1.3rem' }} />
          </Button>
          <Button sx={styles.directionArrow} variant="secondary" onClick={onClickNextUser}>
            <KeyboardArrowRightIcon sx={{ fontSize: '1.3rem' }} />
          </Button>
        </Box>
      )}
      <Typography component="h1" sx={styles.name} noWrap>
        {`${user.firstname} ${user?.lastname?.toUpperCase()}`}
      </Typography>
      <Typography component="h4" sx={styles.title} noWrap>
        {user.title}
      </Typography>
      {!disableSectionChip && (
        <Box sx={styles.chips}>
          {user.hoursOfExperience && (
            <UserChip
              color="purple"
              icon={<HourglassBottomIcon sx={styles.chipIcon} />}
              label={user.hoursOfExperience}
            />
          )}
          {user.internalCoach != null && (
            <UserChip
              sxLabel={{ textTransform: 'capitalize' }}
              color={isInternalCoach ? 'green' : 'red'}
              icon={
                isInternalCoach ? (
                  <AccountBalanceIcon sx={styles.chipIcon} />
                ) : (
                  <PersonOutlineIcon sx={styles.chipIcon} />
                )
              }
              label={isInternalCoach ? t('internal') : t('external')}
            />
          )}
          {user.maxCapacity != null && (
            <UserChip
              color="black"
              icon={<PeopleAltOutlinedIcon sx={styles.chipIcon} />}
              label={t('mentoring_available', { count: user.maxCapacity, sessionLabel })}
            />
          )}
          {availabilitiesCount != null && (
            <Chip
              sx={{ marginBottom: '8px' }}
              size="small"
              label={t('availabilities_count', { count: availabilitiesCount })}
              color={availabilitiesCount ? 'info' : 'default'}
              variant="outlined"
            />
          )}
        </Box>
      )}
      <Box sx={styles.containerContactDetails}>
        <Typography component="h2" sx={styles.contactDetailsTitle} noWrap>
          {t('profile_user.contact_details')}
        </Typography>
        {contactDetails.map(({ id, Icon, label }) => {
          return (
            <Box sx={styles.contactDetailsLine} key={id} onClick={() => onClickContact(id)}>
              <Icon sx={styles.iconContractDetails} />
              <Typography component="h5" sx={styles.valueContractDetails} noWrap>
                {label}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.containerMeet}>
        <Typography component="h4" sx={styles.secondaryTitle} noWrap>
          {t('meet')}
        </Typography>
        <Box sx={styles.contentMeet}>
          {meetDetails.map(({ id, Icon, label }) => {
            return (
              <Box sx={styles.meetItem} key={id}>
                <Icon sx={styles.meetItemIcon} />
                <Typography component="h5" sx={styles.valueContractDetails} noWrap>
                  {label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const UserAboutMe = ({ user }) => {
  const { t } = useTranslation();

  const userDepartment = user?.department instanceof Object ? user?.department?.name : user?.department;

  const cardTimeZoneDescription = user.timeZoneDescription ? 5 : 0;
  const cardDepartment = userDepartment ? (user.timeZoneDescription ? (12 - cardTimeZoneDescription) / 2 : 5) : 0;

  const cardSectorsTitle = 12 - cardTimeZoneDescription - cardDepartment;

  const languages = user.languagesSpoken || user.languages || [];

  const isObjectives = user.coachingSkills == null;
  const skillsOrObjectives = user.coachingSkills || user.objectives || [];

  const isMobile = useMediaQuery('(max-width:400px)');

  return (
    <Box sx={styles.card}>
      <Typography component="h4" sx={styles.aboutMeTitle} noWrap>
        {t('user_about_me.title')}
      </Typography>

      <Grid container spacing={2}>
        {!!skillsOrObjectives.length && (
          <Grid item md={7}>
            <Typography component="h4" sx={styles.secondaryTitle} noWrap>
              {t(`user_about_me.${isObjectives ? 'objective_title' : 'expertise_title'}`)}
            </Typography>
            {
              <Box sx={{ gap: '8px', display: 'flex', flexWrap: 'wrap' }}>
                {skillsOrObjectives?.map((expertise, index) => {
                  return (
                    <UserChip
                      key={index}
                      color="black"
                      label={expertise}
                      noWrap={!isMobile}
                      sxLabel={{ maxWidth: isMobile ? '200px' : 'max-content' }}
                      noBorder
                    />
                  );
                })}
              </Box>
            }
          </Grid>
        )}
        <Grid item md={5}>
          <Typography component="h4" sx={styles.secondaryTitle} noWrap>
            {t('user_about_me.languages_title')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TranslateOutlinedIcon sx={styles.iconContractDetails} />
            <Typography component="p" sx={styles.aboutMeValue}>
              {languages
                ?.map((item) => {
                  return t(getLabelByValue(item));
                })
                .join(', ')}
            </Typography>
          </Box>
        </Grid>
        {user.scopeName && (
          <Grid item md={cardSectorsTitle}>
            <Typography component="h4" sx={styles.secondaryTitle} noWrap>
              {t('user_about_me.sectors_title')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DataSaverOffOutlinedIcon sx={styles.iconContractDetails} />
              <Typography component="p" sx={styles.aboutMeValue}>
                {user.scopeName}
              </Typography>
            </Box>
          </Grid>
        )}
        {userDepartment && (
          <Grid item md={cardDepartment}>
            <Typography component="h4" sx={styles.secondaryTitle} noWrap>
              {t('user_about_me.department_title')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BusinessOutlinedIcon sx={styles.iconContractDetails} />
              <Typography component="p" sx={styles.aboutMeValue} noWrap={!isMobile}>
                {userDepartment}
              </Typography>
            </Box>
          </Grid>
        )}
        {user.timeZoneDescription && (
          <Grid item md={cardTimeZoneDescription}>
            <Typography component="h4" sx={styles.secondaryTitle} noWrap>
              {t('user_about_me.time_zone_title')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LanguageOutlinedIcon sx={styles.iconContractDetails} />
              <Typography component="p" sx={styles.aboutMeValue} noWrap={!isMobile}>
                {user.timeZoneDescription}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const UserView = ({
  user = {},
  onClickPreviousUser,
  onClickNextUser,
  company = {},
  userRole = '',
  isLoading = false,
  directionalArrowsActivated = false,
  availabilitiesEditable = false,
  availabilitiesDisabled = false,
  editButtonDisplay = false,
  typeView = 'coach',
  onSelectAvailability,
  onlyFullAvailabilities = false,
  redBorderNoAvailabilities = false,
  subtitleAvailabilities = '',
  scrollToAvailabilities = false,
  isCurrentUser = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openActiveSettings, setOpenActiveSettings] = useState(false);
  const { expertLabel, clientLabel, selfRegistrationActivated } = company;
  const { data: availabilities, isLoading: isLoadingAvailabilities } = useCoachAvailabilities(
    user.id,
    onlyFullAvailabilities,
    !!selfRegistrationActivated && !availabilitiesDisabled && !!user.id
  );
  const spacing = 3;

  const showCoachEdit = !!(
    [roleList.RH_MANAGER, roleList.RH, roleList.COACH, roleList.ADMIN].includes(userRole) && editButtonDisplay
  );

  const showUserEdit =
    !showCoachEdit &&
    editButtonDisplay &&
    (isCurrentUser || [roleList.RH_MANAGER, roleList.RH, roleList.ADMIN].includes(userRole));

  const { actions, state } = useEditCoach(user.id, company.id, !showCoachEdit);

  if (isLoading) return <p>Loading...</p>;

  const cardUserAboutMe = user.videoLink ? 8 : 12;

  const handleNavigate = () => {
    if (typeView === 'coach') {
      navigate(`/coach/edit/${user.id}`);
    } else if (typeView === 'mentee') {
      navigate(`/coachee/edit/${user.id}`);
    }
  };

  setTimeout(() => {
    const element = document.querySelector('.availabilities-card');
    if (element && scrollToAvailabilities) {
      document.querySelector('.availabilities-card').scrollIntoView({ behavior: 'smooth' });
    }
  }, 250);

  const showEditButtonAvailabilities =
    [roleList.RH_MANAGER, roleList.RH, roleList.COACH].includes(userRole) && availabilitiesEditable;

  const onSwitchChange = (e) => {
    if (e.target.checked) {
      actions.submitForm();
    } else {
      setOpenActiveSettings(true);
    }
  };

  const showBio =
    user?.descriptionExperience?.replaceAll('<p></p>', '')?.trim() || user?.bio?.replaceAll('<p></p>', '')?.trim();

  return (
    <Grid sx={styles.container} container spacing={spacing}>
      {showCoachEdit && !state.query.isLoading && (
        <Box sx={styles.containerEditButton}>
          <CoachActivationSwitch
            control={state.form.control}
            active={actions.watch('active')}
            deactivatedFrom={actions.watch('deactivatedFrom')}
            deactivatedUntil={actions.watch('deactivatedUntil')}
            onSwitchChange={(e) => onSwitchChange(e)}
          />

          <Button variant="primary" onClick={handleNavigate}>
            {[roleList.RH_MANAGER, roleList.RH].includes(userRole) ? t('edit_this_expert') : t('edit_my_profile')}
          </Button>

          <ActiveSettingsCoachModal
            open={openActiveSettings}
            control={state.form.control}
            setValue={actions.setValue}
            watch={actions.watch}
            onClose={() => {
              actions.resetActive();
              setOpenActiveSettings(false);
            }}
            onValidate={() => {
              setOpenActiveSettings(false);
              actions.submitForm();
            }}
          />
        </Box>
      )}
      {showUserEdit && (
        <Box sx={styles.containerEditButton}>
          <Button variant="primary" onClick={handleNavigate}>
            {[roleList.RH_MANAGER, roleList.RH].includes(userRole) ? t('edit_this_expert') : t('edit_my_profile')}
          </Button>
        </Box>
      )}
      <Grid item xs={12} md={4} lg={3}>
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={12}>
            <UserGeneralInformation
              availabilitiesCount={availabilities?.length}
              user={user}
              sessionLabel={company.sessionLabel}
              directionalArrowsActivated={directionalArrowsActivated}
              onClickNextUser={onClickNextUser}
              onClickPreviousUser={onClickPreviousUser}
            />
          </Grid>
          {selfRegistrationActivated && !availabilitiesDisabled && !isLoadingAvailabilities && (
            <Grid item xs={12} md={12}>
              <CoachAvailabilities
                availabilities={availabilities}
                availabilitiesEditable={showEditButtonAvailabilities}
                coachId={user.id}
                redBorderNoAvailabilities={redBorderNoAvailabilities}
                subtitle={subtitleAvailabilities}
                onSelectAvailability={onSelectAvailability}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={9} spacing={spacing}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={cardUserAboutMe}>
              <UserAboutMe user={user} />
            </Grid>
            {user.videoLink && (
              <Grid item xs={12} md={4} spacing={spacing}>
                <ReactPlayer url={user.videoLink} width="100%" height="100%" />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!showBio && (
          <Grid item xs={12} md={12}>
            <Box sx={[styles.card, { marginTop: '24px' }]}>
              <Typography component="h4" sx={styles.aboutMeTitle} noWrap>
                {t('user_biography_title')}
              </Typography>

              {parse(user.descriptionExperience || user.bio)}
            </Box>
          </Grid>
        )}
        {user.expectations && (
          <Grid item xs={12} md={12}>
            <Box sx={[styles.card, { marginTop: '24px' }]}>
              <Typography component="h4" sx={styles.aboutMeTitle} noWrap>
                {t(`${typeView}_expectations_title`, { expertLabel, clientLabel })}
              </Typography>

              {parse(user.expectations.replaceAll(/\r\n|\n|\r/g, '<br/>'))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default UserView;
